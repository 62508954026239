export const messages = {
    sv: {
        home: {
            header: 'Välkommen till Pläjset!'
        },
        article:{
            allergens: '<span>Vid allergi eller ändringar, vänligen beställ av personal i kassan \n Alkohol beställs i kassan</span>'
        },
        label: {
            articleChoiceLabel1: 'Extra burgare', 
            articleChoiceLabel2: 'Anpassa',
            articleChoiceLabel3: 'Välj tillbehör',
            articleChoiceLabel4: 'Ändra dryck',
            articleChoiceLabel5: 'Ändra tillval',
        },
        selectedShop: {
            extraInfo: 'Vid allergi eller ändringar i menyerna, vänligen beställ av personal i kassan \n Alkohol beställs i kassan'
        },
    },
    en:{},
    fi:{},
    no:{},
    ru:{}
}