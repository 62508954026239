import {defineStore} from "pinia";
import {couponStoreKey} from "../keys";
import {ref} from "vue";
import {Coupon, ICoupon} from "../types";
import {DateTime} from "luxon";
import axios from "axios";

export const CouponStore = defineStore(couponStoreKey, () => {
    const coupons = ref<Coupon[]>([]);

    const loadFromStorage = (e: StorageEvent | undefined | null) => {
        //If event was thrown by irrelevant storage
        if (e && e.key !== couponStoreKey) {
            return;
        }
        const json: string | null = localStorage.getItem(couponStoreKey);
        if (json) {
            try {
                const data = JSON.parse(json) as ICoupon[]
                coupons.value = data.map(x=>new Coupon(x))
            } catch (ex) {
                //If json is corrupt, remove data.
                clear();
            }
        }
    }
    function disabled():boolean{
        return coupons.value.length === 0;
    }

    function set(item: Coupon[]) {
        const oldCoupons = coupons.value;
        if(oldCoupons.length > 0){
            item.forEach(newCoupon => {
                const oldCoupon = oldCoupons.find(x=>x.id === newCoupon.id);
                if(oldCoupon) {
                    newCoupon.usedDate = oldCoupon.usedDate;
                }
            })
        }
        
        coupons.value = item;
        
        console.log(coupons.value);
        //Store items in local storage.
        localStorage.setItem(couponStoreKey, JSON.stringify(coupons.value));
    }
    
    function useCoupon (couponId:number){
        const couponFromStore = coupons.value.find(x=>x.id === couponId);
        if(!couponFromStore)
            throw new Error("Coupon not found from store");
        
        couponFromStore.usedDate = new Date();
        set(coupons.value);
    }
    
    function expire(couponId:number){
        const couponFromStore = coupons.value.find(x=>x.id === couponId);
        if(!couponFromStore)
            throw new Error("Coupon not found from store");

        couponFromStore.usedDate = DateTime.now().minus({minutes:10}).toJSDate();
        set(coupons.value);
    }

    const load = () => {
        return new Promise((resolve: any, reject: any) => {
            axios.get<ICoupon[]>(`/api/coupons`)
                .then((response) => response.data)
                .then((data:ICoupon[]) => {
                    set(data.map(x=>new Coupon(x)));
                    resolve(data);
                }).catch(err => {
                    //reject(err);
                });
        });
    }

    function clear() {
        coupons.value = [];
        localStorage.removeItem(couponStoreKey);
    }

    loadFromStorage(null);
    window.addEventListener('storage', loadFromStorage);

    return {
        coupons,
        load,
        loadFromStorage,
        clear,
        useCoupon,
        expire,
        disabled
    }
});