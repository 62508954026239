import { errorStoreKey} from '@/keys';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { errorToi18nKey } from '@/functions';



export const ErrorMessageStore = defineStore(errorStoreKey, () => {

    /* interface */
    interface ErrorStoreState {
        visible: boolean,
        message: string
    }

    /* State */
    const state = ref<ErrorStoreState>({
        visible: false,
        message: ''
    });


    /* Setters */
    const close = () => {
        state.value.message = '';
        state.value.visible = false;
    }

    const show = (error: string | number) => {
        state.value.message = typeof error === 'number' ? errorToi18nKey(error) :  error;
        state.value.visible = true;
    }


    /* Getters */

    return {
        state,
        show,
        close
    }
})