import {createI18n} from 'vue-i18n';
import en from './en'
import sv from './sv'
import fi from './fi'
import ru from './ru'
import {isDebug} from "@/functions";

const messages = {
    en: en,
    sv: sv,
    fi: fi,
    ru: ru
}

const getLocaleFromCookie = ()=>{
    const cookie = document.cookie.split(';').find(c=>c.includes('.AspNetCore.Culture'))
    let culture = 'sv';
    if(cookie){
        const cookieValue = decodeURIComponent(cookie.split('=')[1]);
        const cultureValues = cookieValue.split('|');
        if(cultureValues.length > 1){
            culture = cultureValues[1].split('=')[1]; //uic value of aspnet core culture cookie
        }
    }
    console.log('culture', culture);
    return culture;
}


export default createI18n({
    locale: getLocaleFromCookie(),
    fallbackLocale: isDebug() ? undefined:"en",
    legacy: false,
    messages
})