import { navigationStoreKey } from '@/keys';
import { NavigationStoreState } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';


export const NavigationStore = defineStore(navigationStoreKey, () => {
    /* State */
    const state = ref<NavigationStoreState>({
        visible: true,
        text: '',
        link: {
            name: 'home', params: {}
        },
        url: ''       
    });

    return {
        state
    }
})