
export enum CartItemType {
    Article,
    Suggested
}

export enum OrderType {
    TakeAway = "takeAway",
    DineInPlace = "dineInPlace"
}


export enum ScreenType {
    Large = 'large',
    Small = 'small'
}

export enum OrderStatusState {
    CalculatedPrice = 'calculatedPrice',
    Added = 'added',
    Started = 'started',
    Finnished = 'finnished',
    Done = 'done',
    Delivered = 'delivered',
    Rollbacked = 'rollbacked',
}