import {CartItemType, OrderStatusState, OrderType} from "@/enums";
import {RouteParamsRaw} from "vue-router";
import {DateTime, Duration} from "luxon";
import MenuItem from "@/components/MenuItem.vue";
import {generateSlug} from "@/functions";


//Maps to OrderSumary server-side
export interface Order {
    shopId: number,
    orderId:string,
    shopName: string,
    orderType: OrderType,
    pickupTime?: Date | null,
    totalSum?: number,
    partialSum?: number,
    taxSum?: number,
    taxPercentage?: number,
    customerCode: string | null,
    items: OrderItem[],
    note:string|null,
    deliverySpotId?:number|null,
    created?: Date | null
}

export interface OrderItem {
    code: string,
    quantity: number,
    price?: number,
    choices: OrderChoice[],
    name?: string,
    imageUrl?: string,
    isChoice?: boolean | null
}

export interface OrderChoice {
    level: number,
    articleCode: string,
    choiceCode: string,
    price?: number,
    name?: string,
    imageUrl?: string
}

export interface ApplicationSettings {
    enableCustomerLogin: boolean,
    orderTypes: OrderType[],
    externalLoginProviders: string[],
    version:string
}
export interface OrderResponse {
    shopId: number,
    shopName: string,
    orderType: OrderType,
    pickupTime: Date | null,
    totalSum: number,
    partialSum: number,
    taxSum: number,
    taxPercentage: number,
    items: OrderItem[]
}


export interface Shop {
    id: number | null;
    name: string;
    location: Location;
    distance: number;
    description: string;
    status: StatusType;
    contactInformation: ContactInformation;
    openingHours: OpeningHour[],
    externalAppUri:null,
    organizationId:string|null
    openForBusiness: boolean;
}

export interface Location {
    longitude: number;
    latitude: number;
}

export interface OpeningHour {
    id: number,
    opens: string,
    closes: string,
    dayOfWeek: string,
    validFrom?: boolean,
    validThrough?: boolean,
    closed: boolean
}

export type StatusType = "online" | "offline" | "paused";

export interface ContactInformation {
    address1: string;
    address2: string;
    city: string;
    zipCode: string;
    phone: string|null;
}

export interface Cart {
    items: CartItem[]
    shopId: number,
    shopName: string,
    orderType: OrderType,
    pickupTime?: Date,
    note?:string|null,
    settings: ApplicationSettings,
    spotId:number|null
}

export interface CartItem {
    type: CartItemType,
    article: Article;
    choices: SelectedChoices;
    flatChoices: Choice[];
    categoryName?: string,
    quantity: number;
    id: string
}

export interface CartState {
    visible: boolean,
    showOrderInfo: boolean,
    showAddItemButton: boolean,
    hasSelectedSuggestions: boolean,
    disabled: boolean,
    orderType: OrderType,
    shopId: number | null,
    shopName?: string,
    items: CartItem[],
    editMode: boolean,
    currentOrderChanged: boolean,
    note: string|null,
    spotId:number|null,
    settings: ApplicationSettings,
    initiated: boolean
}

export interface SuggestedArticleCategory {
    description: string,
    order: number,
    articleIds: string[]
}

export interface Article {
    code: string;
    choices: ChoiceLevel[];
    description: string;
    description2: string;
    imageUrl: string;
    price: number;
    salesUnit: string;
    suggestedArticleIds: string[];
    taxCode: number;
    taxCodeSecondary: number;
    additionalInformation: string;
    availableForSale: boolean;
    suggestedArticles: SuggestedArticleCategory[]
    stickers: Sticker[];
    campaigns: Campaign[];
    i18n: Translations,
}
export interface Sticker {
    name:string;
    description:string;
    imageId:string;
    i18n: Translations
}

export interface Campaign {
    name:string;
    description:string;
    enabled:boolean;
    imageId:string;
    code:string;
    i18n: Translations,
    membersOnly?:boolean,
    shopIds?:number[]
    stickers: Sticker[],
    articleCodeLink?:string|null
}

export class Translations {
    [property:string]:any;
    tr = (property:string, culture:string): string => {
        const translations = this[property] as Translation[];
        const text = translations.find(t => t.language === culture)?.text;
        
        return text || "";
    };
}

export interface Translation{
    language: string,
    text: string
}

export interface ArticleInfo {
    imageUrl: string;
    name: string;
    code: string;
}

export interface Category {
    articles: string[],
    imageUlr: string,
    maxPrice: number,
    minPrice: number,
    name: string,
    i18n: Translations
}

export interface Menu{
    name:string,
    items:MenuItem[],
    currency:Currency
    choiceLevels: ChoiceLevel[];
    getArticleItem(articleCode:string):{ item: MenuItem | null, path: string[]} | null;
    getItemFromPath(path:string[]):MenuItem|null;
}

export class MenuImpl implements Menu{
    constructor(data:Menu) {
        this.name = data.name;
        this.items = data.items;
        this.currency = data.currency;
        this.choiceLevels = data.choiceLevels;
    }

    name: string;
    items: MenuItem[];
    currency: Currency;
    choiceLevels: ChoiceLevel[];
    

    
    private getItemFromPathInternal(items:MenuItem[], path:string[]):MenuItem|null{
        for(const item of items){
            if(generateSlug(item.name) === path[0]){
                if(path.length === 1){
                    return item;
                }
                if(item.type === 'container'){
                    return this.getItemFromPathInternal(item.items!, path.slice(1));
                }
            }
        }
        return null;
    }   
    public getItemFromPath(path:string[]):MenuItem|null{
        return this.getItemFromPathInternal(this.items, path);
    }
    
    public getArticleItem(articleCode:string):{ item: MenuItem | null, path: string[] } | null {
        return this.findArticleItemInMenu(this.items, articleCode, null);
    }
    
    private findArticleItemInMenu(items:MenuItem[],articleCode:string, path:string[] | null | undefined):{ item: MenuItem | null, path: string[] } | null {
        for(const item of items){
            if(item.type === 'article' && item.code === articleCode){
                return {
                    item,
                    path: path ?? []
                };
            }
            if(item.type === 'container'){
                const found = this.findArticleItemInMenu(item.items!, articleCode, [...(path??[]), item.name]);
                if(found){
                    return found;
                }
            }
        }
        return null;
    }
} 

export interface MenuItem{
    name:string;
    type:string;
    i18n?: Translations;
    itemId:number;
    parentId:number;
    items?:MenuItem[];
    image?:string;
    code?:string;
    article?:Article;
}

export interface Choice {
    order: number;
    article: Article;
    quantity: number;
    unit: string;
    code: string;
    id: number;
    level: number;
    type: ChoiceType;
    subRecipes: Choice[];
}
export type ChoiceType = "recipe" | "subRecipe";

export interface ChoiceLevel {
    level: number;
    name: string;
    isMultipleChoice: boolean;
    choices: Choice[];
    minChoices: number;
    maxChoices: number;
    nameOverride:string|null;
    callToActionOverride:string|null;
    i18n: Translations
}

export interface Currency {
    name: string,
    symbol: string,
}

export interface SelectedChoices {
    [key: number]: Choice[];
}

export interface Category {
    name: string;
    imageUrl: string;
    maxPrice: number;
    minPrice: number;
    articles: string[];
}

export interface MinMax {
    min: number,
    max: number
}

export interface ApiStatus {
    done: boolean,
    failed: boolean,
    loading: boolean,
    text?: string
    error?: ResponseError
}


export interface CartInject {
    cart: CartItem[] | null,
    addToCart(item: CartItem): any,
    removeFromCart(item: CartItem): any
}


export interface SuggestedArticlesInject {
    suggestedArticleIds: string[] | null,
    setSuggestedArticleIds(articleIds: string[]): any
}

export interface NavigationStoreState {
    text: string,
    visible: boolean,
    url: string,
    category?: string,
    link?: NavigationStoreLink | undefined,


}//RouteLocationRaw

export interface NavigationStoreLink {
    name: string,
    path?: string,
    params: RouteParamsRaw | undefined,
    text?: string
}

export interface Languages { [key: string]: string }

export interface DropdownItem {
    label: string;
    value: string;
}

export interface OrderStoreState {
    details: OrderDetails | null
    //status: string | null,
    //orderStatus: OrderStatus | null,
    paidOrders: PaidOrder[],
    deliveredOrders: PaidOrder[]
}

export interface PaidOrder {
    id: string,
    customerCode: string | null
    expires: Date;
}

export interface OrderDetails {
    orderId: string | null;
    shopId: number | null;
    accessToken: string | null;
    pickupTime: Date | null;
    totalAmount: number | null;
    customerCode: string | null
    paymentID: string | null;
    userAgent: string | null;
}

export interface OrderHistory {
    webOrders: OrderStatus[]
    others: OrderStatus[]
}

export type PaginatedResult<T> = {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
    items: T[];
}

export interface OrderStatus {
    orderId: string
    orderNr: string
    totalAmount: number
    created: string
    shop: Shop
    items: OrderStatusItem[]
    orderType: string
    status: OrderStatusState,
    pickupTime: string
    paymentTransactionId: any
    note:string|any,
    deliverySpot: DeliverySpot|null,
    currency: Currency|null,
    isWebOrder?: boolean
}

export interface OrderStatusItem {
    amount: number
    totalAmount: number
    price: number
    description: string
    articleCode: string
    normalPrice: number
    purchasePrice: number
    quantity: number
    campaign: Campaign | null
    choices: any[],
    isChoice: boolean,
    isVat?: boolean,
}

export interface ResponseError {
    type: string
    title: string
    status: number,
    detail: string,
    errors?: any
}

export interface ICoupon {
    id: number,
    created: Date,
    validUntil: Date,
    name: string,
    description: string,
    imageUrl: string,
    usedDate: Date | null
}

export class Coupon implements ICoupon{
    constructor(data:ICoupon) {
        this.id = data.id;
        this.created= new Date(data.created);
        this.validUntil = new Date(data.validUntil);
        this.name = data.name;
        this.description = data.description;
        this.imageUrl = data.imageUrl;
        this.usedDate = data.usedDate ? new Date(data.usedDate) : null;
    }

    id: number;
    created: Date;
    validUntil: Date;
    name: string;
    description: string;
    imageUrl: string;
    usedDate: Date | null;
    
    public get state() : CouponState {
        if(!this.usedDate){
            return CouponState.Unused;
        }

        const expires = new Date(this.usedDate);
        expires.setMinutes(this.usedDate.getMinutes()+10);

        if(expires < new Date()){
            return CouponState.Expired
        }

        return CouponState.Active;
    }
    
    getStateClass() : string {
        switch (this.state) {
            case CouponState.Unused:
                return 'coupon-unused';
            case CouponState.Active:
                return 'coupon-active';
            case CouponState.Expired:
                return 'coupon-expired';
        }         
    }

    getTimeLeftForCoupon():Duration {
        
        const expires = DateTime.fromJSDate(this.usedDate?? new Date()).plus({minutes:10});
        const duration =expires.diff(DateTime.local(), ['minutes', 'seconds']);
        return duration;
        //console.log(eh.toFormat('mm:ss',{ floor: true }));
        
        
        // const used = DateTime.fromJSDate(this.usedDate);
        //
        // return DateTime.local().diff(used, ['minutes', 'seconds']);
        
        //
        // const diff = Math.abs(new Date().setMinutes(new Date().getMinutes()) - this.usedDate.getTime());
        // const diff2 = (1000*60*10)-diff;
        //
        // const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, diff2)),
        //     // Pull out parts of interest
        //     parts = [
        //         d.getUTCHours(),
        //         d.getUTCMinutes(),
        //         d.getUTCSeconds()
        //     ],
        //     // Zero-pad
        //     formatted = parts.map(s => String(s).padStart(2, '0')).join(':');
        //
        // return d;
    }
    
}
export enum CouponState {
    Unused = 0,
    Active = 1,
    Expired = 2
}

export interface AuthResponse {
    tokenType: string,
    accessToken: string,
    expiresIn: number,
    expires: Date,
    refreshToken: string,
}
export interface ProfileResponse {
    name:string,
    lastName:string,
    email:string,
    isAuthenticated:boolean,
    favorites: Favorite[]
}

export interface UpdateProfileRequest {
    name:string,
    lastName:string,
    email:string,   
    password:string|null
    newPassword:string|null
    confirmNewPassword:string|null,
    redirectUri?: string|null
}

export interface Favorite {
    id?: number|null,
    code: string,
    type:string
    article?: Article|null,
    shop?: Shop|null
}

export interface Terms {
    i18n: Translations
}


export interface DeliverySpot {
    id:number,
    name:string,
    i18n: Translations,
    orderType: OrderType
}