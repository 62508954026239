export default {
  home: {
    header: 'Välkommen!',
    subHeader: 'Välj din restaurang'
  },
  list: {
    menu: 'Meny'
  },
  shopchanged: {
    header: 'Varning!',
    text: 'Du har en pågående beställning kopplad till en annan restaurang än den nuvarande.'
  },
  checkout: {
    header: 'Kassa',
    pickup: 'När ska maten vara klar?',
    orderHeader: 'Min beställning',
  },
  payment: {
    header: 'Betala',
    confirmLeave: {
      header: 'Bekräfta tillbaka',
      text: 'Är du säker på att du vill lämna sidan? Om du lämnar nu kanske vi inte kan uppdatera dig med korrekt orderstatus då din betalning fortfarande pågår.',
      confirm:'Ja, jag är säker',
      cancel:'Nej, jag vill stanna kvar'
    }
  },
  termsAndConditions: {
    header: 'Allmänna villkor',
    closeButton: 'Stäng'
  },
  suggestedArticles: {
    header: 'Får vi erbjuda något annat?',
  },
  selectedShop: {
    header: 'Välj var du vill äta',
    extraInfo: ''
  },
  search: {
    label: 'Sök restaurang',
    placeholder: 'Hitta restaurang',
    noMatch: 'Ingen matchande restaurang'
  },
  shopSelector: {
    singleShop: {
      start: "Beställ här",
      closed: "Stängt för idag",
      paused: "Beställning pausad",
      offline: "Offline",
      openingHoursHeader: "Dagens öppettider på webben",
    }
  },
  button: {
    showMore: 'Visa mer',
    showMoreRestaurants: 'Visa fler restauranger',
    open: 'Öppet',
    closed: 'Stängt',
    paused: 'Beställning pausad',
    offline: 'Offline',
    dineInPlace: 'På restaurangen',
    takeAway: 'Ta med',
    pay: 'Betala',
    order: 'Beställ',
    orderNotReady: 'Beställningen är inte klar',
    change: 'Ändra',
    noThanks: 'Nej tack',
    done: 'Klar',
    cancel: 'Avbryt',
    changeRestaurant: 'Byt restaurang',
    changeLanguage: 'Byt språk',
    cancelOrder: 'Avbryt beställningen',
    yes: 'Ja',
    no: 'Nej',
    add: 'Lägg till',
    back: 'Tillbaka',
    backToCategories: 'Tillbaka till menyn',
    backToCategory: 'Tillbaka till {category}',
    backToArticle: 'Tillbaka till {article}',
    backToHome: 'Avbryt beställning',
    backToShopPage: 'Avbryt beställning',
    backToMenuRoot: 'Tillbaka',
    receipt: 'Kvitto',
    favorite: 'Favorit',
    choiceDone: 'Klar',
  },
  item: {
    notAvailable: 'Tillfälligt slut',
    favoriteHeader: 'Mina favoriter'
  },
  label: {
    total: 'Totalt',
    subtotal: 'Netto',
    tax: 'Varav moms ({percent}%)',
    dineInPlace: 'Äta på restaurangen',
    takeAway: 'Ta med',
    articleChoiceLabel1: 'Anpassa',
    articleChoiceLabel2: 'Byt tillbehör',
    articleChoiceLabel3: 'Lägg till extra tillbehör',
    articleChoiceLabel4: 'Ändra dryck',
    articleChoiceLabel5: 'Välj smak',
    now: 'Så snart som möjligt',
  },
  order: {
    header: 'Min beställning',
    pageHeader: 'Din beställning',
    thankYouText: 'Tack för din beställning',
    infoText: '(Tryck på produkt för att ändra)',
    infoText2: '(ev. rabatter dras i nästa steg)',
    emptyInfoText: 'Inga varor i korgen ännu',
    selectedRestaurant: 'Vald restaurang',
    missing: 'Beställningen saknas',
    pickUpLabel: 'Beräknad hämtningstid',
    statusLabel: 'Nuvarande status',
    numberLabel: 'Beställningsnummer',
    note: {
      label: "Övrig information",
      placeholder: "Ange övrig information",
      remove: "Ta bort"
    },
    deliverySpot: {
      label: "Levereras till",
      empty: "Ingen vald..."
    },
    status: {
      calculatedprice: {
        label: "Kontrollerar beställning...",
        description: ""
      },
      added: {
        label: 'Mottagen',
        description: "Din beställning är mottagen"
      },
      started: {
        label: 'Tillagas!',
        description: "Din beställning tillagas."
      },
      finnished: {
        label: 'Klart',
        description: "Din beställning är redo att hämtas"
      },
      delivered: {
        label: "Upphämtad",
        description: "Hoppas maten smakar"
      },
      rollbacked: {
        label: "Avbruten",
        description: "Din beställning är avbryten. Reservationen på kortet är borttagen, har du betalat med Swish bör du kontakta restaurangen för återbetalning."
      }
    },
    campaignLabel: 'Erbjudande'
  },
  loader: {
    text: 'Laddar...',
    languages: 'Hämtar språkval',
    categories: 'Hämtar kategorier',
    favorites: 'Hämtar dina favoriter',
    orderHistory: 'Hämtar din beställningshistorik',
    image: '',
    calculatesDistances: 'Beräknar avstånd',
    checkoutSummary: 'Kontaktar restaurangen',
  },
  cancel: {
    header: 'Avbryt',
    text: 'Vill du verkligen avbryta?',
    order: {
      header: 'Avbryt beställning',
      description: 'Vill du avbryta din beställning?',
      confirm: 'Ja, avbryt',
      cancel: 'Nej, fortsätt',
    }
  },
  error: {
    heading: 'Felmeddelande',
    closeButtonText: 'Stäng',
    server: 'Det uppstod ett serverfel, försök igen.',
    badRequest: 'Din webbläsare skickade en begäran som den här servern inte kunde förstå.',
    notFound: 'Sidan kunde inte hittas. Detta kan bero på att artikeln eller sidan inte finns tillgänlig för denna restaurang.',
    unknown: 'Okänt fel, försök igen. ',
    "One or more validation errors occurred": "Ett eller flera valideringsfel uppstod",
    InvalidToken: "Din lösenordsåterställningslänk är ogiltig eller har gått ut, vänligen begär en ny länk.",
    PasswordRequiresNonAlphanumeric: "Lösenordet måste innehålla minst ett specialtecken.",
    PasswordRequiresDigit: "Lösenordet måste innehålla minst en siffra ('0'-'9').",
    PasswordRequiresLower: "Lösenordet måste innehålla minst en liten bokstav ('a'-'z').",
    PasswordRequiresUpper: "Lösenordet måste innehålla minst en stor bokstav ('A'-'Z').",
    PasswordTooShort: "Lösenordet måste innehålla minst 6 tecken",
    PasswordMismatch: "Lösenorden matchar inte.",
    DuplicateUserName: "Ett konto med denna e-postadress finns redan.",
    DuplicateEmail: "Testa logga in eller glömt lösenord istället.",
    deliverySpotRequired: "Vänligen välj en leveransplats innan du fortsätter.",
    order: {
      "unavailable-products": "Någon av produkterna i din varukorg är inte längre tillgängliga för beställning",
      "shop-closed": "Restaurangen du försöker beställa till är stängd, försök igen senare.",
      "shop-closed-at-pickuptime": "Restaurangen du försöker beställa till är stängd vid den angivna upphämtningstiden",
      "shop-paused": "Restaurangen du försöker beställa till tar tyvärr inte emot beställningar via webben just nu, försök igen senare.",
      "create-order-failed": "Tyvärr gick det för närvarande inte att skapa din beställing, var god försök igen senare.",
      "create-order-failed-missing-recipe-items": "Någon av produkterna i din beställning saknar nödvändiga ingredienser.",
      "preorder-create-order-failed":{
        "invalid-shop-id": "Ogiltigt restaurang-ID, var god kontrollera och försök igen.",
        "shop-not-found": "Restaurangen du försöker beställa till är stängd eller har stängt för gott, försök igen senare.",
        "internal-server-error": "Ett okänt fel uppstod med ordersystemet, var god försök igen senare.",
        "missing-parameter": "Ett nödvändigt fält saknas, var god kontrollera din beställning och försök igen.",
        "invalid-parameter": "En eller flera ogiltiga värden angavs, var god kontrollera och försök igen.",
        "shop-paused": "Restaurangen du försöker beställa till tar tyvärr inte emot beställningar via webben just nu, försök igen senare.",
        "shop-offline": "Restaurangen du försöker beställa till är offline, försök igen senare.",
        "shop-method-not-supported": "Restaurangen stöder inte denna beställningsmetod, försök med en annan metod.",
        "shop-error-internal-error": "Ett internt fel uppstod hos restaurangen, var god försök igen senare.",
        "shop-error-invalid-date-time": "Ogiltigt datum eller tid angivet, var god kontrollera och försök igen.",
        "shop-error-date-time-in-the-past": "Det angivna datumet eller tiden är i det förflutna, var god välj ett senare datum/tid.",
        "shop-error-timeout-waiting-for-call-lock": "Tidsgräns överskreds medan systemet väntade på svar, var god försök igen.",
        "shop-error-terminal-not-found": "Restaurangens terminal kunde inte hittas, försök igen senare.",
        "shop-error-stock-center-not-found": "Lagercenter kunde inte hittas, försök igen senare.",
        "shop-error-recipe-items-missing": "Vissa ingredienser saknas för beställningen, vänligen kontakta restaurangen.",
        "shop-error-internal-error-in-call": "Ett internt fel inträffade under samtalet med restaurangen, försök igen senare.",
        "shop-error-order-not-found": "Beställningen kunde inte hittas, var god kontrollera din beställningsinformation.",
        "shop-error-order-is-delivered": "Beställningen har redan levererats.",
        "shop-error-order-is-deleted": "Beställningen har raderats.",
        "shop-error-order-is-started": "Beställningen har redan påbörjats och kan inte ändras.",
        "shop-error-database-error": "Ett fel uppstod med databasen, försök igen senare.",
        "shop-error-failed-to-open-database": "Kunde inte öppna databasen, försök igen senare.",
        "shop-error-failed-to-init-coffe-card": "Kunde inte initiera kaffekortet, försök igen senare.",
        "shop-error-failed-to-init-coffe-card-campaing": "Kunde inte initiera kaffekortkampanjen, försök igen senare.",
        "shop-error-profit-center-not-found": "Vinstcenter kunde inte hittas, kontakta supporten.",
        "shop-error-payment-not-found": "Betalningen kunde inte hittas, var god försök igen.",
        "shop-error-slots-not-in-use": "Tidsluckor används inte för tillfället, försök igen senare.",
        "shop-error-cashier-not-found": "Kassör kunde inte hittas, försök igen senare.",
        "shop-error-currency-error": "Ett valutaförhållningsfel uppstod, var god kontrollera och försök igen.",
        "shop-error-articles-not-available": "Vissa artiklar är inte tillgängliga, vänligen uppdatera din beställning.",
        "shop-error-slot-not-available": "Den valda tidsluckan är inte tillgänglig, vänligen välj en annan.",
        "shop-error-internal-error-post-order-check-error": "Ett internt fel uppstod efter beställningen, försök igen.",
        "shop-error-internal-error-post-order-fixed": "Ett internt fel uppstod men det har åtgärdats, försök att fortsätta.",
        "shop-error-internal-error-post-order-not-fixed": "Ett internt fel uppstod som inte kunde åtgärdas, försök igen senare.",
        "shop-error-no-central-web-plu": "Det centrala webbundernumret saknas, var god kontakta support.",
        "unknown-error": "Ett okänt fel uppstod, var god försök igen."
      }
    },
    payment: {
      failed: 'Det uppstod tyvärr ett fel vid betalningen (kod:01)',
      aborted: 'Det uppstod tyvärr ett fel vid betalningen (kod:02)',
      notPaid: 'Det uppstod tyvärr ett fel vid betalningen (kod:03)',
      invalid: 'Det uppstod tyvärr ett fel vid betalningen (kod:04)',
      accesstokenMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:05)',
      IdentifierMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:06)',
      NotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:07)',
      merchantNotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:08)',
      orderNotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:09)',
      returnUrl: 'Det uppstod tyvärr ett fel vid betalningen (kod:10)',
      requestParametersMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:11)',
      orderFailed: 'Det uppstod tyvärr ett fel vid betalningen (kod:12)',
      captureFailed: 'Det uppstod tyvärr ett fel vid betalningen (kod:13)',
      createError: 'Det uppstod tyvärr ett fel vid betalningen (kod: 14)',
      loadScriptError: 'Det uppstod tyvärr ett fel vid betalningen (kod: 15)',
    },
    user: {
      "invalid-password": "Lösenordet uppfyller inte de minimikrav som ställs.",
      "register-user-failure": "Misslyckades att skapa konto",
      "password-mismatch": "Lösenorden matchar inte",
      "send-email-confirmation-failed": "Misslyckades att skicka e-postbekräftelse",
    },
    menu: {
      "not-found": "Menyn kunde inte hittas för restaurangen med id {shopId}",
    }
  },
  privacyPolicy: {
    header: 'Sekretess policy',
    policy: "                            <p>Vi på Winpos respekterar din integritet och har åtagit oss att skydda din personliga information. Denna integritetspolicy beskriver den information vi samlar in, hur vi använder den och hur vi skyddar den i samband med din användning av vår weborder/app.</p>\n" +
      "                            <h3>Information vi samlar in</h3>\n" +
      "                            <p>Vi kan samla in personuppgifter som du tillhandahåller när du använder vår weborder/app, till exempel din IP-adress. Vi kan också samla in icke-personlig information, till exempel din enhetstyp och användningsdata.</p>\n" +
      "                            <h3>Hur vi använder din information</h3>\n" +
      "                            <p>Vi använder den personliga information vi samlar in för att uppfylla din beställning, tillhandahålla kundsupport och kommunicera med dig om vår weborder/app. Vi kan också använda din information för att skicka dig marknadsföringskommunikation om våra andra produkter och tjänster.</p>\n" +
      "                            <p>Vi kan dela din information med tredje parts tjänsteleverantörer som hjälper oss att uppfylla din beställning och tillhandahålla kundsupport. Vi kan också dela din information med våra dotterbolag och partners för marknadsföringsändamål.</p>\n" +
      "                            <h3>Rättslig grund för behandling</h3>\n" +
      "                            <p>Enligt GDPR behandlar vi dina personuppgifter på den rättsliga grunden för ditt samtycke eller för att uppfylla ett avtal med dig. Vi har också ett berättigat intresse av att behandla din information för kundsupport och marknadsföringsändamål.</p>\n" +
      "                            <h3>Hur vi skyddar din information</h3>\n" +
      "                            <p>Vi vidtar rimliga åtgärder för att skydda din personliga information från obehörig åtkomst, avslöjande, ändring och förstörelse. Vi använder säkerhetsåtgärder av branschstandard, såsom kryptering och säkra servrar, för att skydda din information.</p>\n" +
      "                            <h3>Dina rättigheter</h3>\n" +
      "                            <p>Enligt GDPR har du rätt att få tillgång till, korrigera, radera och begränsa behandlingen av dina personuppgifter. Du har också rätt att invända mot behandlingen av dina personuppgifter för marknadsföringsändamål.</p>\n" +
      "                            <p>Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss på info@winpos.se. Vi kommer att svara på din förfrågan inom 30 dagar.</p>\n" +
      "                            <h3>Uppdateringar av denna sekretesspolicy</h3>\n" +
      "                            <p>Vi kan uppdatera denna sekretesspolicy från tid till annan för att återspegla förändringar i vår praxis eller tillämplig lag. Vi kommer att meddela dig om eventuella väsentliga ändringar genom att publicera den uppdaterade sekretesspolicyn på vår webbplats eller via vår weborder/app.</p>\n" +
      "                            <h3>Kontakta oss</h3>\n" +
      "                            <p>Om du har några frågor eller funderingar om denna sekretesspolicy eller vår GDPR-efterlevnad, vänligen kontakta oss på info@winpos.se.</p>\n" +
      "                            <p>Ikraftträdandedatum: 2023-05-01</p>"
  },
  receipt: {
    heading: "Kvitto",
    total: "Totalt",
    amountPrefix: "á",
    vat: "Moms",
    net: "Netto",
    orderNumber: "Beställnings nr",
    paymentId: "Betalnings id",
    deliverySpot: "Levereras till",
    note: "Övrig information",
    email: "E-post:",
    emailPlaceHolder: "Skriv in din e-postadress",
    sendEmail: "Skicka kvitto",
    loading: "Skickar kvittot...",
    emailSent: "Kvitto skickat",
    emailFailed: "Misslyckades att skicka kvittot via e-post, vänligen försök igen.",
    deliveryDate: "Leveranstid",
  },
  article: {
    allergens: 'Allergisk? Prata med oss i personalen så hjälper vi dig.'
  },
  offline: {
    header: 'Det går inte ansluta till internet',
    text: 'Kontrollera din internetanslutning och försök igen.',
    button: 'Försök igen'
  },
  updateApp: {
    text: 'En ny version är tillgänglig, vänligen uppdatera för att fortsätta.',
    button: 'Uppdatera'
  },
  popup: {
    buttons: {
      close: 'Stäng'
    },
  },
  login: {
    header: 'Logga in',
    email: 'E-post',
    password: 'Lösenord',
    registerText: 'Saknar du konto?',
    registerLink: 'Skapa konto',
    confirm: 'Logga in',
    forgotPassword: 'Glömt lösenord',
    separatorText: 'Eller',
    failed: 'Misslyckades att logga in, försök igen',
    googleSignIn: 'Logga in med Google',
    facebookSignIn: 'Logga in med Facebook'
  },
  forgotPassword: {
    header: 'Glömt lösenord',
    text: 'Har ni glömt ert lösenord? Fyll i din e-post för att få ett återställningsmejl.',
    email: {
      label: 'Ange e-post',
      placeholder: 'E-post'
    },
    send: {
      text: 'Skicka'
    },
    sent: {
      header: 'Skickat',
      message: 'Ett återställningsmail har skickats till angivna epost, har ni inte fått något mail inom några minuter finns det inget konto kopplat till angiven e-post.',
      newAccountText: 'Skapa ett konto'
    },
  },
  resetPassword: {
    header: 'Återställ lösenordet',
    password: {
      label: 'Nytt lösenord',
      placeholder: 'Ange ett nytt lösenord'
    },
    confirmPassword: {
      label: 'Bekräfta det nya lösenordet',
      placeholder: 'Ange det nya lösenordet igen'
    },
    send: {
      text: 'Återställ'
    },
    reset: {
      header: 'Lösenordet återställt',
      message: 'Ditt lösenord har återställts, du kan nu logga in med ditt nya lösenord.'
    }
  },
  campaign: {
    bannerText: 'Erbjudande'
  },
  memberCard: {
    header: 'Mitt digitala klubb-kort',
    text: 'Skanna mig i kassan eller i självbetjänten'
  },
  orderHistory: {
    header: 'Beställningshistorik',
    empty: 'Du har ingen beställningshistorik ännu'
  },
  favorites: {
    header: 'Mina favoriter',
    empty: 'Du har inga favoriter ännu'
  },
  register: {
    header: 'Skapa konto',
    success: {
      header: 'Konto skapat',
      text: 'Ett mail har skickats till angiven e-post, vänligen kolla din inkorg och följ instruktionerna för att bekräfta din e-postadress. Efter det kan du logga in.',
      loginLink: 'Logga in'
    },
    firstname: {
      label: 'Förnamn',
      placeholder: 'Ange förnamn'
    },
    lastname: {
      label: 'Efternamn',
      placeholder: 'Ange efternamn'
    },
    email: {
      label: 'E-post',
      placeholder: 'Ange e-post'
    },
    password: {
      label: 'Lösenord',
      placeholder: 'Ange lösenord'
    },
    confirmPassword: {
      label: 'Upprepa lösenordet',
      placeholder: 'Upprepa lösenordet'
    },
    acceptTerms: {
      label: 'Jag godkänner ',
      linkText: 'villkoren'
    },
    passwordMismatch: {
      text:'Lösenorden matchar inte'
    },
    registerButton: 'Skapa konto'

  },
  confirmEmail: {
    header: "E-post bekräftad",
    text: "Ditt konto har bekräftats, du kan nu logga in.",
  },
  navBar: {
    login: 'Logga in'
  },
  campaigns: {
    header: 'Erbjudanden just nu',
    description: 'Nedan ser du alla erbjudanden som är aktiva just nu, är du inloggad så kan det finnas fler erbjudanden.',
    descriptionAuthenticated: 'Nedan ser du alla erbjudanden som är aktiva just nu.',
    membersOnly: {
      text: 'Medlemserbjudande'
    },
    localOffer: {
      text: 'Lokalt erbjudande'
    },
    offer: {
      text: 'Erbjudande'
    },
    toArticle: {
      text: 'Till erbjudandet'
    },
    expandButton: {
      text: "Visa mer"
    },
    showMoreButton: {
      text: "Se alla erbjudanden"
    },
  },
  profile: {
    edit: {
      header: "Mitt konto",
      submit: {
        text: "Spara",
        title: "Spara kontouppgifter"
      },
      userInformation: {
        header: "Användarinformation",
        name: {
          label: "Namn",
          placeholder: "Skriv in ditt namn",
          description: "Ditt namn kommer visas på ditt konto"
        },
        lastName: {
          label: "Efternamn",
          placeholder: "Skriv in ditt efternamn",
          description: "Ditt efternamn kommer visas på ditt konto"
        },
        email: {
          label: "E-post",
          placeholder: "Skriv in din e-post",
          description: "Din e-post använder du när du loggar in"
        },
      },
      changePassword: {
        header: "Byt lösenord",
        password: {
          label: "Nuvarande lösenord",
          placeholder: "Skriv in ditt nuvarande lösenord",
          description: "Skriv in det lösenord du använder idag för att logga in"
        },
        newPassword: {
          label: "Nytt lösenord",
          placeholder: "Skriv in ditt nya lösenord",
          description: "Det lösenord som du vill byta till"
        },
        confirmNewPassword: {
          label: "Bekräfta det nya lösenordet",
          placeholder: "Skriv in det nya lösenordet igen",
          description: "Det lösenord som du vill byta till, igen"
        },
      },
      emailConfirmationSent: "Ett mail har skickats till angiven e-post, vänligen kolla din inkorg och följ instruktionerna för att bekräfta din e-postadress.",
      success: {
        header: "Sparat!",
        text: "Dina uppgifter har sparats"
      },
      deleteAccount: {
        text: "Ta bort konto",
        title: "Ta bort ditt konto från tjänsten",
        confirm: {
          header: "Ta bort konto??",
          description: "Är du verkligen helt säker på att du vill ta bort kontot?",
          ok: "Ja, ta bort",
          cancel: "Avbryt"
        }
      },
    },
    menu: {
      userGreeting: 'Hej!',
      offers: 'Erbjudanden',
      favorites: 'Mina favoriter ',
      orderHistory: 'Beställningshistorik',
      account: 'Mitt konto',
      logout: 'Logga ut'
    },
  }
}