import { appStoreKey} from '@/keys';
/*import { AppStoreState } from '@/types';*/
import { ScreenType } from '@/enums';
import { BREAKPOINTS } from '@/breakpoints'
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { RouteRecordName} from "vue-router";


export const AppStore = defineStore(appStoreKey, () => {

    /* interface */
    interface AppStoreState {
        height: number,
        width: number
        screenType: ScreenType,
        isSmallScreen: boolean,
        isLargeScreen: boolean,
        language: string,
        showLangSelector: boolean,
        currentPage: RouteRecordName | null | undefined;
        lastPage: RouteRecordName | null | undefined;
        currentPageFullPath: string| null | undefined;
        lastPageFullPath: string | null | undefined;
    }

    /* State */
    const state = ref<AppStoreState>({
        height: 0,
        width: 0,
        screenType: ScreenType.Large,
        isLargeScreen: true,
        isSmallScreen: false,
        language: 'sv',
        showLangSelector: false,
        currentPage: null,
        lastPage: null,
        currentPageFullPath: null,
        lastPageFullPath: null
    });

    /* Getters */


    const onResize = (): void => {
      
        state.value.width = window.innerWidth;
        state.value.height = window.innerHeight;
        state.value.screenType = BREAKPOINTS.tabletLandscape > state.value.width ? ScreenType.Small : ScreenType.Large;
        state.value.isSmallScreen = state.value.screenType === ScreenType.Small;
        state.value.isLargeScreen = !state.value.isSmallScreen;
    }

    window.addEventListener('resize', onResize);
    onResize();

    return {
        state
    }
})