import {defineStore} from "pinia";
import {authStorageKey, cartStorageKey} from "../keys";
import {ref} from "vue";
import {Article, AuthResponse, Favorite, OrderHistory, OrderStatus, PaginatedResult, ProfileResponse, Shop, UpdateProfileRequest} from "@/types";
import axios from "axios";

export interface AuthState {
  isAuthenticated: boolean;
  token: AuthResponse | null;
  user: ProfileResponse | null
  loading: boolean;
}

export const AuthStore = defineStore(authStorageKey, () => {
  const state = ref<AuthState>({
    token: null,
    user: null,
    isAuthenticated: false,
    loading: true
  });

  const loadFromStorage = (e: StorageEvent | undefined | null) => {
    if (e && e.key !== authStorageKey) {
      return;
    }

    getProfile().then(profile => {
      state.value.user = profile;
      state.value.isAuthenticated = profile?.isAuthenticated ?? false;
      state.value.loading = false;
    }).catch(e => {
      if (e.response.status == 401) {
        //not authenticated
        state.value.user = null;
        state.value.isAuthenticated = false;
        state.value.loading = false;
      }
    });
  };

  const login = async (email: string, password: string): Promise<AuthState> => {

    const response = await axios.post('/api/identity/login?useCookies=true&useSessionCookies=true', JSON.stringify({email, password}));
    if (response.status !== 200) {
      //failed
    }

    const profile = await getProfile()
    if (profile === null) {
      state.value.user = null;
      state.value.isAuthenticated = false;
    } else {
      state.value.user = profile;
      state.value.isAuthenticated = profile.isAuthenticated;
    }
    save();
    return state.value;
  }
  
  const waitForLoading = async () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (state.value.loading === false) {
          clearInterval(interval);
          resolve(state.value.loading);
        }
      }, 100);
    });
  }

  const getProfile = async (): Promise<ProfileResponse | null> => {
    state.value.loading = true;
    const profileResponse = await axios.get<ProfileResponse>('/api/user/profile');
    if (profileResponse.status !== 200) {
      state.value.loading = false;
      return null;
    }
    const profile: ProfileResponse = profileResponse.data;
    state.value.user = profile
    state.value.isAuthenticated = profile.isAuthenticated
    state.value.loading = false;
    return profile;
  }

  const logout = () => {
    return axios.post('/api/user/logout').then(response => {
        state.value.token = null;
        state.value.user = null;
        state.value.isAuthenticated = false;
        save();
      });
  };

  const save = () => {
    const obj = {
      token: state.value.token,
      user: state.value.user,
      isAuthenticated: state.value.isAuthenticated
    } as AuthState;
    localStorage.setItem(authStorageKey, JSON.stringify(obj));
  }

  const addFavoriteArticle = async (code: string): Promise<any> => {
    const response = await axios.post('/api/user/favorite', {code: code, type: 'article'});
    if (response.status !== 200) {
      //TODO do something
      return;
    }

    state.value.user?.favorites.push({code: code, type: 'article'});
    save();
  }

  const addFavoriteShop = async (shopId: string): Promise<any> => {
    const response = await axios.post('/api/user/favorite', {code: shopId, type: 'shop'});
    if (response.status !== 200) {
      //TODO do something
      return;
    }

    state.value.user?.favorites.push({code: shopId, type: 'shop'});
    save();
  }

  const removeFavoriteArticle = async (code: string): Promise<any> => {
    const response = await axios.delete<Favorite[]>('/api/user/favorite', {
      data: JSON.stringify({code: code, type: 'article'})
    });

    if (response.status !== 200) {
      //TODO do something
      return;
    }

    if (state.value.user) {
      state.value.user.favorites = response.data;
    }

    save();
  }
  const removeFavoriteShop = async (code: string): Promise<any> => {
    const response = await axios.delete<Favorite[]>('/api/user/favorite', {
      data: JSON.stringify({code: code, type: 'shop'})
    });
    if (response.status !== 200) {
      //TODO do something
      return;
    }

    if (state.value.user) {
      state.value.user.favorites = response.data;
    }
    save();
  }

  const getOrderHistory = async (pageIndex:number, pageSize:number) => {
    if (state.value.isAuthenticated === false)
      return [];

    return axios.get<PaginatedResult<OrderStatus>>(`/api/user/order-history?pageIndex=${pageIndex}&pageSize=${pageSize}`)
      .then(response => response.data);
  }

  const setProfile = (profile: ProfileResponse) => {
    if (state.value.isAuthenticated === false)
      throw new Error('Not authenticated');

    state.value.user = profile;
    //state.value.loading = true;
    // return axios.put<{ emailConfirmationSent: boolean, profile: ProfileResponse }>('/api/user/profile', profile)
    //   .then(response => {
    //       state.value.user = response.data.profile;
    //       state.value.isAuthenticated = response.data.profile.isAuthenticated;
    //       state.value.loading = false;
    //       save();
    //       return response;
    //   });
  }

  loadFromStorage(null);
  window.addEventListener('storage', loadFromStorage);

  return {
    state,
    login,
    logout,
    addFavoriteArticle,
    addFavoriteShop,
    removeFavoriteArticle,
    removeFavoriteShop,
    getOrderHistory,
    setProfile,
    waitForLoading
    //updateProfile
  }
});