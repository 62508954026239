export default {
  home: {
    header: 'Добро пожаловать! Выберите свой ресторан',
  },
  checkout: {
    header: 'Kassan',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  },
  suggestedArticles: {
    header: 'Får vi erbjuda något annat?',
  },
  selectedShop: {
    header: 'Выберите, где поесть'
  },
  search: {
    label: 'Поиск ресторана',
    placeholder: 'Найти ресторан',
    noMatch: 'Нет подходящего ресторана'
  },
  button: {
    showMore: 'Visa mer',
    showMoreRestaurants: 'Показать больше ресторанов',
    open: 'открыть',
    closed: 'Закрыто',
    paused: 'Stängt på webben',
    dineInPlace: 'Есть здесь',
    takeAway: 'Приносить',
    pay: 'Betala',
    order: 'Beställ',
    change: 'Ändra',
    noThanks: 'Nej tack',
    done: 'Klar',
    cancel: 'Avbryt',
    changeRestaurant: 'Изменить ресторан',
    changeLanguage: 'переключить язык',
    cancelOrder: 'Avbryt order',
    yes: 'Да',
    no: 'Нет'
  },
  label: {
    total: 'Totalt',
    dineInPlace: 'Äta här',
    takeAway: 'Ta med',
    articleChoiceLabel1: 'Ändra/lägg till ingredienser',
    articleChoiceLabel2: 'Ändra',
    articleChoiceLabel3: 'Lägg till extra tillbehör',
    articleChoiceLabel4: 'Ändra',
    articleChoiceLabel5: 'Välj smak',
  },
  order: {
    header: 'Min beställning',
    infoText: '(Tryck på produkt för att ändra)',
    emptyInfoText: 'Inga varor i korgen ännu'
  },
  loader: {
    text: 'Letar efter hamburgare',
    languages: 'Letar efter språkval',
    categories: 'Letar efter kategorier',
    image: ''
  },
  cancel: {
    header: 'Avbryt',
    text: 'Vill du verkligen avbryta?',
    order: {
      header: 'Avbryt beställning',
      description: 'Vill du avbryta din beställning?',
      confirm: 'Ja, avbryt',
      cancel: 'Nej, fortsätt',
    }
  }
}