export default {
  home: {
    header: 'Tervetuloa!',
    subHeader: 'Valitse ravintola',
  },
  list: {
    menu: 'Menu'
  },
  shopchanged: {
    header: 'Varoitus!',
    text: 'Sinulla on odottava tilaus toisessa ravintolassa kuin nyt valitussa ravintolassa.'
  },
  checkout: {
    header: 'Kassa',
    pickup: 'Noutoaika',
    orderHeader: 'Tilaukseni',
  },
  payment: {
    header: 'Maksa tilaus'
  },
  termsAndConditions: {
    header: 'Käyttöehdot'
  },
  suggestedArticles: {
    header: 'Voimmeko tarjota jotain muuta?',
  },
  selectedShop: {
    header: 'Valitse missä haluat syödä',
  },
  search: {
    label: 'Etsi ravintola',
    placeholder: 'Löydä ravintola',
    noMatch: 'Vastaavia ravintoloita ei löytynyt'
  },
  shopSelector: {
    singleShop: {
      start: "Aloita tilaus",
      closed: "Suljettu tältä päivältä",
      paused: "Suljettu tilauksilta",
      openingHoursHeader: "Aukioloajat",
    }
  },
  button: {
    showMore: 'Näytä enemmän',
    showMoreRestaurants: 'Näytä enemmän ravintoloita',
    open: 'Auki',
    closed: 'Suljettu',
    paused: 'Suljettu tilauksilta',
    dineInPlace: 'Syön ravintolassa',
    takeAway: 'Otan mukaan',
    pay: 'Maksa',
    order: 'Tilaa',
    change: 'Muokkaa',
    noThanks: 'Ei kiitos',
    done: 'Valmis',
    cancel: 'Peruuta',
    changeRestaurant: 'Vaihda ravintola',
    changeLanguage: 'Vaihda kieli',
    cancelOrder: 'Peru tilaus',
    yes: 'Kyllä',
    no: 'Ei',
    add: 'Lisää',
    back: 'Takaisin',
    receipt: 'Kuitti'

  },
  item: {
    notAvailable: 'tuote loppu',
  },
  label: {
    total: 'Yhteensä',
    subtotal: 'Välisumma',
    tax: 'Alv ({percent}%)',
    articleChoiceLabel1: 'Muokkaa/lisää',
    articleChoiceLabel2: 'Muokkaa',
    articleChoiceLabel3: 'Valitse lisukkeet',
    articleChoiceLabel4: 'Muokkaa',
    articleChoiceLabel5: 'Valitse maku',
    dineInPlace: 'Syön ravintolassa',
    takeAway: 'Otan mukaan',
    now: 'Nyt'
  },
  order: {
    header: 'Tilaukseni',
    pageHeader: 'Tilauksesi',
    thankYouText: 'Kiitos tilauksestasi',
    infoText: '(Napauta tuotetta tehdäksesi muutoksia)',
    emptyInfoText: 'Ei vielä tuotteita ostoskorissa',
    selectedRestaurant: 'Valittu ravintola',
    missing: 'Tilaus puuttuu',
    pickUpLabel: 'Arvioitu noutoaika',
    statusLabel: 'nykyinen tila',
    numberLabel: 'Tilausnumerosi',
    note: "Toimitus osoitteeseen:",
    status: {
      processing: "Vahvistetaan tilausta...",
      received: 'Tilauksesi on vastaanotettu',
      started: 'Tilaustasi valmistellaan!',
      readyforpickup: 'Valmis noudettavaksi!',
      delivered: "Toimitettu!",
      rollbacked: "Tilaus peruttu, korttisi varaus on poistettu. Jos olet maksanut Swishillä, ota yhteyttä ravintolaan rahan palautusta varten."
    }
  },
  loader: {
    text: 'Ladataan...',
    languages: 'Etsitään kielivaihtoehtoja',
    categories: 'Etsitään kategorioita',
    image: '',
    calculatesDistances: 'Lasketaan etäisyydet',
  },
  cancel: {
    header: 'Peruutus',
    text: 'Haluatko perua tilauksen?',
    order: {
      header: 'Peru tilaus',
      description: 'Haluatko perua tilauksesi?',
      confirm: 'Kyllä, perun tilauksen',
      cancel: 'Ei, jatka',
    }
  },
  error: {
    heading: 'Virheviesti',
    server: 'Palvelinvirhe, yritä uudelleen.',
    badRequest: 'Selaimesi lähetti pyynnön, jota tämä palvelin ei ymmärtänyt.',
    unknown: 'Tuntematon virhe, yritä uudelleen.',
    order: {
      "unavailable-products": "Yksi tai useampi tilauksesi tuotteista ei ole enää saatavilla.",
      "shop-closed": "Ravintola ei ole enää auki, yritä myöhemmin uudelleen.",
      "shop-closed-at-pickuptime": "Ravintola on suljettu valitsemanasi noutoaikana.",
      "shop-paused": "Ravintola ei valitettavasti ota vastaan verkkotilauksia juuri nyt, yritä myöhemmin uudelleen.",
      "create-order-failed": "Jostain syystä emme voineet luoda tilaustasi, yritä myöhemmin uudelleen.",
    },
    payment: {
      failed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:01)',
      aborted: 'Maksun yhteydessä tapahtui tuntematon virhe (code:02)',
      notPaid: 'Maksun yhteydessä tapahtui tuntematon virhe (code:03)',
      invalid: 'Maksun yhteydessä tapahtui tuntematon virhe (code:04)',
      accesstokenMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:05)',
      IdentifierMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:06)',
      NotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:07)',
      merchantNotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:08)',
      orderNotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:09)',
      returnUrl: 'Maksun yhteydessä tapahtui tuntematon virhe (code:10)',
      requestParametersMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:11)',
      orderFailed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:12)',
      captureFailed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:13)',
      createError: 'Maksun yhteydessä tapahtui tuntematon virhe (code: 14)',
      loadScriptError: 'Maksun yhteydessä tapahtui tuntematon virhe (kod: 15)',
    }
  },
  privacyPolicy: {
    header: 'Tietosuojakäytäntö'
  },
  receipt: {
    heading: "Kuitti",
    total: "Yhteensä",
    vat: "Alv",
    net: "Netto",
    orderNumber: "TilausNro",
    paymentId: "Maksutunnus",
    note: "Toimitus osoitteeseen",
    email: "Sähköpostiosoite:",
    emailPlaceHolder: "Syötä sähköpostiosoitteesi",
    sendEmail: "Lähetä kuitti",
    emailSent: "Sähköposti lähetetty",
    deliveryDate: "Toimitusajankohta",
  },
  article: {
    allergens: 'Tietoa allergeeneista <a href="#">somewhere</a>'
  }
}