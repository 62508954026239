//export const cartInjectionKey = "cart-inject"; // Symbol();//.for('cart-inject').toString();

export const suggestedArticlesStorage = "suggestedArticlesStorage";

export const menuStoreKey = "menuStorage"; // Symbol.for('menu').toString();

export const cartStorageKey = "cartStorage"; // Symbol().toString();//.for('store').toString();
export const authStorageKey = 'authStorage';

export const shopStorageKey = "shopStorage";

export const navigationStoreKey = "navigation";

export const appStoreKey = "app-store";

export const addItemEventKey = "add-item-event";

export const articleInfoStoreKey = "articleInfoStore"

export const orderStoreKey = "orderStorage";

export const errorStoreKey = "errorStoreKey";
export const couponStoreKey = "coupon-store";